// 换件维修换件维修换件维修换件维修换件维修换件维修换件维修换件维修换件维修换件维修
// 左侧设备列表
const equList = [
  { id: "MY20230301010", status: "待换件" },
  { id: "MY20230301011", status: "待换件" },
  { id: "MY20230301012", status: "待换件" },
  { id: "MY20230301013", status: "待换件" },
  { id: "MY20230301014", status: "待换件" },
  { id: "MY20230301015", status: "正常" },
  { id: "MY20230301016", status: "待换件" },
  { id: "MY20230301017", status: "待换件" },
  { id: "MY20230301018", status: "正常" },
  { id: "MY20230301019", status: "待换件" },
];
// 左侧列表标题换件状态
const equTitle1 = [
  { id: "deviceCode", name: "设备编号" },
  { id: "replaceTypeName", name: "换件状态" },
];
// 左侧列表标题运维巡检
const equTitle2 = [
  { id: "deviceCode", name: "设备编号" },
  { id: "deviceCheckStatusStr", name: "换件状态" },
];
// 卡片数据(换件维修)
const cardArray = [
  {
    id: 1,
    value: "0",
    unit: "",
    title: "维修换件次数",
    valueName: "repair",
  },
  {
    id: 2,
    value: "0",
    unit: "",
    title: "到期换件次数",
    valueName: "replaces",
  },
  {
    id: 3,
    value: "0",
    unit: "天",
    title: "平均换件周期",
    valueName: "period",
  },
  {
    id: 4,
    value: "0",
    unit: "天",
    title: "最长换件周期",
    valueName: "longPeriod",
  },
  {
    id: 5,
    value: "0",
    unit: "天",
    title: "最短换件周期",
    valueName: "shortPeriod",
  },
];
// 表格数据
// const tableData = [
//   {
//     partName: "上模加热模块",
//     partNum: "LJ292992",
//     avgCycle: "30h",
//     cumTimes: "8",
//     lastDate: "2023.04.19 14:58",
//     cumTime: "30h",
//     lastResult: "维修换件",
//     person: "段志鑫",
//   },
//   {
//     partName: "上模加热模块",
//     partNum: "LJ292992",
//     avgCycle: "30h",
//     cumTimes: "8",
//     lastDate: "2023.04.19 14:58",
//     cumTime: "30h",
//     lastResult: "维修换件",
//     person: "段志鑫",
//   },
//   {
//     partName: "上模加热模块",
//     partNum: "LJ292992",
//     avgCycle: "30h",
//     cumTimes: "8",
//     lastDate: "2023.04.19 14:58",
//     cumTime: "30h",
//     lastResult: "维修换件",
//     person: "段志鑫",
//   },
//   {
//     partName: "上模加热模块",
//     partNum: "LJ292992",
//     avgCycle: "30h",
//     cumTimes: "8",
//     lastDate: "2023.04.19 14:58",
//     cumTime: "30h",
//     lastResult: "维修换件",
//     person: "段志鑫",
//   },
// ];
// 表格标题数据
const titleList = [
  { id: "componentName", title: "零件名称" },
  { id: "componentCode", title: "零件类型编号" },
  { id: "newPeriod", title: "平均换件周期" },
  { id: "totalNum", title: "累计换件次数" },
  // { id: "startDate", title: "零件添加时间" },
  { id: "lastDate", title: "上次换件时间" },
  { id: "newTotalRunTime", title: "累计运行时长" },
  { id: "type", title: "上次换件类型" },
  { id: "reason", title: "上次换件原因" },
  { id: "lastOperate", title: "上次换件人" },
  { id: "replaces", title: "换件状态" },
];
// 表格数据(换件维修记录)
const tableData1 = [
  {
    date: "2023-04-19 14:58",
    RepType: "维修换件",
    component: "上模加热模块",
    brand: "云典典",
    partId: "LJ299201",
    operator: "段志鑫",
  },
  {
    date: "2023-04-19 14:58",
    RepType: "维修换件",
    component: "上模加热模块",
    brand: "云典典",
    partId: "LJ299201",
    operator: "段志鑫",
  },
  {
    date: "2023-04-19 14:58",
    RepType: "维修换件",
    component: "上模加热模块",
    brand: "云典典",
    partId: "LJ299201",
    operator: "段志鑫",
  },
  {
    date: "2023-04-19 14:58",
    RepType: "维修换件",
    component: "上模加热模块",
    brand: "云典典",
    partId: "LJ299201",
    operator: "段志鑫",
  },
];
// 表格标题数据(换件维修记录)
const titleList1 = [
  { id: "operateDate", title: "换件日期" },
  { id: "RepTypeName", title: "更换类型" },
  { id: "componentName", title: "零件名称" },
  { id: "startDate", title: "零件添加时间" },

  { id: "brand", title: "零件品牌" },
  { id: "componentCode", title: "零件编号" },
  { id: "totalRunTime", title: "累计运行时间" },
  { id: "reason", title: "换件原因" },
  { id: "operate", title: "操作人" },
];
// 卡片数据(巡检运维)
const cardArray1 = [
  {
    id: 1,
    value: "0",
    unit: "",
    title: "待处理",
    valueName: "waitCount",
  },
  {
    id: 2,
    value: "0",
    unit: "",
    title: "累计处理",
    valueName: "totalCount",
  },
  {
    id: 3,
    value: "0",
    unit: "",
    title: "设备点检",
    valueName: "spotCount",
  },
  {
    id: 4,
    value: "0",
    unit: "",
    title: "设备巡检",
    valueName: "checkCount",
  },
  {
    id: 5,
    value: "0",
    unit: "",
    title: "超期待检",
    valueName: "dateOutCount",
  },
];
// 表格数据(巡检运维)
const tableData2 = [
  {
    content: "固定螺丝是否松动",
    weekly: "1",
    times: "10",
    nextDay: "预期3天未巡检",
    nextTime: "2023.04.19 14:58",
    lastExaminer: "段志鑫",
    lastCharge: "王士攀",
    lastResult: "正常",
  },
  {
    content: "固定螺丝是否松动",
    weekly: "1",
    times: "10",
    nextDay: "10天",
    nextTime: "2023.04.19 14:58",
    lastExaminer: "段志鑫",
    lastCharge: "王士攀",
    lastResult: "异常",
  },
  {
    content: "固定螺丝是否松动",
    weekly: "1",
    times: "10",
    nextDay: "1天",
    nextTime: "2023.04.19 14:58",
    lastExaminer: "段志鑫",
    lastCharge: "王士攀",
    lastResult: "不检查",
  },
  {
    content: "固定螺丝是否松动",
    weekly: "1",
    times: "30",
    nextDay: "预期3天未巡检",
    nextTime: "2023.04.19 14:58",
    lastExaminer: "段志鑫",
    lastCharge: "王士攀",
    lastResult: "正常",
  },
  {
    content: "固定螺丝是否松动",
    weekly: "1",
    times: "10",
    nextDay: "预期3天未巡检",
    nextTime: "2023.04.19 14:58",
    lastExaminer: "段志鑫",
    lastCharge: "王士攀",
    lastResult: "正常",
  },
  {
    content: "固定螺丝是否松动",
    weekly: "1",
    times: "30",
    nextDay: "预期3天未巡检",
    nextTime: "2023.04.19 14:58",
    lastExaminer: "段志鑫",
    lastCharge: "王士攀",
    lastResult: "正常",
  },
  {
    content: "固定螺丝是否松动",
    weekly: "1",
    times: "10",
    nextDay: "预期3天未巡检",
    nextTime: "2023.04.19 14:58",
    lastExaminer: "段志鑫",
    lastCharge: "王士攀",
    lastResult: "正常",
  },
  {
    content: "固定螺丝是否松动",
    weekly: "1",
    times: "10",
    nextDay: "预期3天未巡检",
    nextTime: "2023.04.19 14:58",
    lastExaminer: "段志鑫",
    lastCharge: "王士攀",
    lastResult: "正常",
  },
];
const titleList2 = [
  { id: "checkInfo", title: "检查内容" },
  { id: "newIntervalDays", title: "检查周期" },
  { id: "missCount", title: "漏检次数" },
  { id: "newNextCheckDays", title: "距离下次巡检天数" },
  { id: "nextCheckDate", title: "下次巡检时间" },
  { id: "lastCheckUserName", title: "上次检查人" },
  // { id: "lastCharge", title: "上次责任人" },
  { id: "lastCheckStatusName", title: "上次检查结果" },
];
// 表格标题(巡检运维)
export {
  equList,
  equTitle1,
  equTitle2,
  cardArray,
  titleList,
  tableData1,
  titleList1,
  cardArray1,
  tableData2,
  titleList2,
};

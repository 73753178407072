import actions from "@/qiankun/actions";

let data = <any>actions.actions;
let request = data.request;

// 查询换件维修列表
export function listReplace(query: any) {
  return request({
    url: "/factory/replace/replace/list",
    method: "get",
    params: query,
  });
}

// 换件维修零件统计列表
export function replaceStatisticsList(query: any) {
  return request({
    url: "/factory/replace/report/list",
    method: "get",
    params: query,
  });
}
// 统计数据
export function summary(data: any) {
  return request({
    url: "/factory/replace/report/summary",
    method: "get",
    params: data,
  });
}

// 新增换件记录
export function addReplace(data: any) {
  return request({
    url: "/factory/replace/record",
    method: "POST",
    data: data,
  });
}

// 换件记录列表
export function listReplaceRecord(query: any) {
  return request({
    url: "/factory/replace/record/list",
    method: "get",
    params: query,
  });
}

// 字符在2~20之间
const numTowTwenty = (rule: any, value: any, callback: any) => {
  if (value.length < 2 || value.length > 20) {
    callback(new Error("输入的内容应为2~20个字符"));
  } else {
    callback();
  }
};
// 字符在1~50之间
const numOneFifty = (rule: any, value: any, callback: any) => {
  if (value.length < 1 || value.length > 50) {
    callback(new Error("输入的内容应为1~50个字符"));
  } else {
    callback();
  }
};
// 字符在2~50之间
const numTwoFifty = (rule: any, value: any, callback: any) => {
  if (value.length < 2 || value.length > 50) {
    callback(new Error("输入的内容应为2~50个字符"));
  } else {
    callback();
  }
};

// 字符在2~100之间
const numTwoOneHundred = (rule: any, value: any, callback: any) => {
  if (value.length < 2 || value.length > 100) {
    callback(new Error("输入的内容应为2~100字符"));
  } else {
    callback();
  }
};

// 限制输入数字，最多5个字符，不能有小数点
const digitFiveNonePoint = (rule: any, value: any, callback: any) => {
  let reg = /^[+]{0,1}(\d+)$/g;
  if (value.length > 5) {
    callback(new Error("内容长度不得超过5个字符"));
  } else if (!reg.test(value)) {
    callback(new Error("仅限大于或等于0的整数"));
  } else {
    callback();
  }
};
export {
  numOneFifty,
  numTowTwenty,
  numTwoFifty,
  numTwoOneHundred,
  digitFiveNonePoint,
};
